import React, { useEffect, useState, useContext } from "react";
import { apiGetRequest } from "helpers/Requests";
import { useParams, useNavigate, useLocation } from "react-router";
import { LoginContext } from "helpers/LoginContext";
import { PlusSmIcon } from "@heroicons/react/outline";
import {
  getNameProfile,
  baseURLImage,
  selectedTabState,
  getFormSubTabForParticipantProfile,
  getUrlRole,
  formatDateIntoDMYToDisplay,
} from "Utils/utils";
import { Link } from "react-router-dom";
import { USER_ROLE } from "Constants/constant";
import SectionInputFields from "components/DynamicForms/SectionAndSubSectionFields/SectionsInputFields";
import HumanBodyModal from "components/Modals/HumanBody/HumanBodyModal";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import SideBar from "components/common/SideBar/SideBarNew";
import AlertModal from "components/Modals/alertModal";
import { BiPrinter } from "react-icons/bi";
import { roleAccess } from "helpers/RolesPermission";
import NotificationDropDown from "components/common/NotificationDropDown/NotificationDropDown";

const SupportPlanView = (props) => {
  const { slug: uuid, parentSlug } = useParams();
  const { routeRole = null } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;

  const { sideBarPadding } = useContext(LoginContext);
  const [viewFormData, setViewFormData] = useState(null);
  const [signatures, setSignatures] = useState(null);
  const [formData, setFormData] = useState(null);
  const [openBodyModal, setOpenBodyModal] = useState(false);
  const [selectedBody, setSelectedBody] = useState("");
  const [selectedLegal, setSelectedLegal] = useState(null);
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const fetchFormTempletes = async () => {
    setRequest({ ...request, isRequested: true });
    try {
      let url = null;

      if (routeRole === "worker" && roleAccess([USER_ROLE.Sp_Standard_User])) {
        url = `worker/hrm/submitted-forms-view?form_id=${uuid}`;
      } else {
        url = `${getUrlRole()}/participant/submitted-forms-view?form_id=${uuid}`;
      }

      const res = await apiGetRequest(url);
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      } else if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        setFormData(res?.data?.submitted_form);
        if (res?.data?.submitted_form?.template.length > 0) {
          if (res?.data?.submitted_form?.template) {
            const data = JSON.parse(res?.data?.submitted_form?.template);

            const signatures = JSON.parse(
              res?.data?.submitted_form?.signatures
            );

            setViewFormData(data);

            setSignatures(signatures);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFormTempletes();
    return () => {
      if (getFormSubTabForParticipantProfile(parentSlug)) {
        selectedTabState.save(
          JSON.stringify(getFormSubTabForParticipantProfile(parentSlug))
        );
      }
    };
  }, [location?.pathname]);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <>
      <div className='min-h-full'>
        <section className='print:hidden'>
          <SideBar />
        </section>
        <div className='w-full '>
          <div
            className={`duration-300 bg-gray-50 ${
              sideBarPadding ? `lg:ml-[4rem] ` : `lg:ml-64`
            } 
            `}
          >
            {request.isSuccess && viewFormData && formData ? (
              <>
                <div className='sticky z-[9] top-0 px-6 flex justify-between items-center bg-white h-[80px] border-b border-gray-200'>
                  <h1 className='text-black font-medium capitalize'>
                    {formData && formData.title}
                  </h1>
                  <div className='flex justify-between items-center space-x-2 print:hidden'>
                    <CustomButton
                      clickHandler={() => {
                        window.scrollTo(0, 0);
                        window.print();
                      }}
                      label='Print'
                      Icon={<BiPrinter />}
                    />
                    <CustomButton
                      clickHandler={() => navigate(-1)}
                      label='Back'
                    />
                    <NotificationDropDown />
                  </div>
                </div>
                <div className='max-w-7xl mx-auto'>
                  <h1 className='mt-5 px-3 text-gray-400'>
                    Important Information
                  </h1>
                  <div className='flex w-full justify-between mb-3 mt-2'>
                    <div className='grid grid-cols-5 gap-4 print:grid-cols-3 mr-5 pr-1'>
                      <div className='bg-white border transition ease-in-out duration-150 hover:shadow-lg p-5 w-full mx-3 rounded-md'>
                        <div className='flex items-center space-x-3 lg:space-x-6'>
                          <img
                            onError={(e) =>
                              handleImageError(
                                e,
                                formData.submitted_against || " ",
                                " "
                              )
                            }
                            className='h-10 w-10 rounded-full object-cover'
                            src={
                              formData.participant_pic
                                ? baseURLImage(formData.participant_pic)
                                : getNameProfile(
                                    formData.submitted_against || " ",
                                    " "
                                  )
                            }
                            alt='user'
                          />
                          <div className='font-medium text-lg leading-6 space-y-1'>
                            <p className='text-gray-400 text-xs'>
                              Submitted Against
                            </p>
                            <h2 className='capitalize'>
                              {formData && formData.submitted_against}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className='bg-white border transition ease-in-out duration-150 hover:shadow-lg p-5 w-full mx-3 rounded-md'>
                        <div className='flex items-center space-x-4 lg:space-x-6'>
                          <img
                            onError={(e) =>
                              handleImageError(
                                e,
                                formData.submitted_by || " ",
                                " "
                              )
                            }
                            className='h-10 w-10 rounded-full object-cover'
                            src={
                              formData.profile_picture
                                ? baseURLImage(formData.profile_picture)
                                : getNameProfile(
                                    formData.submitted_by || " ",
                                    " "
                                  )
                            }
                            alt='user profile'
                          />
                          <div className='font-medium text-lg leading-6 space-y-1'>
                            <p className='text-gray-400 text-xs'>
                              Submitted by
                            </p>
                            <h2 className='capitalize'>
                              {formData && formData.submitted_by}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className='bg-white border transition ease-in-out duration-150 hover:shadow-lg p-5 w-full mx-3 rounded-md'>
                        <span>
                          <div className='flex items-center space-x-4 lg:space-x-6'>
                            <div className='font-medium text-lg leading-6 space-y-1'>
                              <p className='text-gray-400 text-xs'>
                                Submitted on
                              </p>
                              <h2>
                                {formData &&
                                  formatDateIntoDMYToDisplay(
                                    formData.posted_by
                                  )}
                              </h2>
                            </div>
                          </div>
                        </span>
                      </div>
                      <div className='bg-white border transition ease-in-out duration-150 hover:shadow-lg p-5 w-full mx-3 rounded-md'>
                        <div className='flex items-center space-x-4 lg:space-x-6'>
                          {/* {formData.updated_by && (
                            <img
                              onError={(e) =>
                                handleImageError(
                                  e,
                                  formData.submitted_by || " ",
                                  " "
                                )
                              }
                              className="h-10 w-10 rounded-full object-cover"
                              src={
                                formData.profile_picture
                                  ? baseURLImage(formData.profile_picture)
                                  : getNameProfile(
                                      formData.submitted_by || " ",
                                      " "
                                    )
                              }
                              alt="user"
                            />
                          )} */}
                          <div className='font-medium text-lg leading-6 space-y-1'>
                            <p className='text-gray-400 text-xs'>
                              Last updated by
                            </p>
                            <h2 className='capitalize'>
                              {formData?.updated_by ?? "-"}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className='bg-white border transition ease-in-out duration-150 hover:shadow-lg p-5 w-full mx-3 rounded-md'>
                        <span>
                          <div className='flex items-center space-x-4 lg:space-x-6'>
                            <div className='font-medium text-lg leading-6 space-y-1'>
                              <p className='text-gray-400 text-xs'>
                                Last updated at
                              </p>
                              <h2>
                                {formatDateIntoDMYToDisplay(
                                  formData?.updated_at
                                ) ?? "-"}
                              </h2>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <h1 className='mt-5 px-3 text-gray-400'>Submitted Form</h1>
                  {viewFormData.length > 0 &&
                    viewFormData.map((section, index) => (
                      <div
                        key={`${section?.section_title}-${index}`}
                        className='pb-5 border-gray-200 mt-2'
                      >
                        <div className='rounded bg-white border shadow-lg mx-3 mt-3'>
                          <h1 className='text-lg mt-3 pl-5 leading-6 font-medium text-gray-500 mb-1'>
                            {section?.section_title}
                          </h1>
                          <p className='text-gray-400 text-xs pl-5 mb-3'>
                            {`Last updated on ${
                              formData && formData.updated_at
                                ? formatDateIntoDMYToDisplay(
                                    formData.updated_at
                                  )
                                : formatDateIntoDMYToDisplay(formData.posted_by)
                            }`}
                          </p>
                          <div className='bg-gray-100 py-5 px-8 grid grid-cols-12 gap-x-8'>
                            <SectionInputFields
                              key={`${index}-${section?.section_title}`}
                              isViewable={true}
                              section={section}
                              index={index}
                              signatures={signatures === null ? [] : signatures}
                              setSelectedBody={setSelectedBody}
                              setOpenBodyModal={setOpenBodyModal}
                              setSelectedLegal={setSelectedLegal}
                            />
                          </div>
                        </div>

                        <div className='relative flex py-5 px-5 items-center print:hidden'>
                          <div className='flex-grow border-t border-gray-300'></div>
                          <Link
                            {...(!roleAccess([USER_ROLE.Sp_Standard_User]) &&
                              (formData.is_complete
                                ? {
                                    to: `/sp-profile/${parentSlug}/${uuid}/update`,
                                    state: {
                                      particpantUuid: state?.particpantUuid,
                                    },
                                  }
                                : {
                                    to: `/view-template${
                                      routeRole === "hrm" ? "/hrm" : ""
                                    }/${
                                      state?.particpantUuid
                                    }/${parentSlug}/${uuid}/${window.btoa(
                                      encodeURIComponent("false")
                                    )}`,
                                  }))}
                            {...(roleAccess([USER_ROLE.Sp_Standard_User]) &&
                              (formData.is_complete
                                ? {
                                    to: `/submitted-form/${parentSlug}/${uuid}/update`,
                                  }
                                : {
                                    to: `/view-template/hrm/${
                                      state?.particpantUuid
                                    }/${parentSlug}/${uuid}/${window.btoa(
                                      encodeURIComponent("false")
                                    )}`,
                                  }))}
                            className='flex flex-shrink mx-4 bg-white text-black font-medium rounded-full shadow px-4 py-2'
                          >
                            <span>
                              <PlusSmIcon
                                className='h-6 w-6 text-black mr-2'
                                aria-hidden='true'
                              />
                            </span>
                            {formData.is_complete
                              ? "Edit Submission"
                              : "Complete Submission"}
                          </Link>
                          <div className='flex-grow border-t border-gray-300'></div>
                        </div>
                      </div>
                    ))}
                </div>
                {openBodyModal && (
                  <HumanBodyModal
                    view={true}
                    selectedValue={selectedBody}
                    isOpen={openBodyModal}
                    closeModal={() => setOpenBodyModal(false)}
                  />
                )}
                {selectedLegal && (
                  <AlertModal
                    show={selectedLegal !== null ? true : false}
                    handleClose={() => setSelectedLegal(null)}
                  >
                    {selectedLegal}
                  </AlertModal>
                )}
              </>
            ) : (
              <div className='bg-gray-50 text-center min-h-screen flex justify-center items-center'>
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportPlanView;
