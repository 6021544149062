import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";

import Search from "components/common/Search/Search";

import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  emptyPaginationObj,
  REPORTING_LIMIT,
  SORT_TYPE,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiPostRequest } from "helpers/Requests";
import { URL_REPORTING_TIMESHEET_REPORTS } from "store/services/URL";
import moment from "moment";
import { FiDelete } from "react-icons/fi";
import _ from "lodash";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { toast } from "react-toastify";
import { addMonths, exportTOExcel, truncateTextReporting } from "Utils/utils";
import PopoverContainer from "components/common/Popover/PopoverContainer";
const TimesheetReports = ({
  getTeamManagmentListCall,
  teamManagementList,
  getParticipantList,
  spParticipants,
}) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [isDisableSearch,setIsDisableSearch]=useState(false)

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
    getTeamManagmentListCall({ filters, bodyFilter: null });
  }, []);

  const [workerList, setWorkerList] = useState([
    { label: "All", value: "all" },
  ]);
  const [participantList, setParticipant] = useState([
    { label: "All", value: "all" },
  ]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);

  useEffect(() => {
    const updateLists = async () => {
      const workers =
        teamManagementList?.employees?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedWorkers = workers.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setWorkerList(sortedWorkers);

      const participants =
        spParticipants?.participant?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedParticipants = participants.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setParticipant(sortedParticipants);

      setParticipant((prevParticipantList) => [
        { label: "All", value: "all" },
        ...prevParticipantList,
      ]);
      setWorkerList((prevWorkerList) => [
        { label: "All", value: "all" },
        ...prevWorkerList,
      ]);
    };

    updateLists();
  }, [teamManagementList, spParticipants]);

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    fetchListReports();
  }, [page]);

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(() => fetchListReports(), 600);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = null
  ) => {
    if (!fromDate && !toDate) {
      toast("Select create date range to get data");
      return;
    }

    setLoader(true);
    let url = `${URL_REPORTING_TIMESHEET_REPORTS}?per_page=25&page=${page}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_TIMESHEET_REPORTS}?per_page=25&page=${page}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",
      participant: isClearAll
        ? "all"
        : selectedParticipant
        ? selectedParticipant?.value
        : "all",
      worker: isClearAll
        ? "all"
        : selectedWorker
        ? selectedWorker?.value
        : "all",

      timesheet_date_range: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
    };
    if (report) {
      payload.report = report === "xlsx" ? "excel" : report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            const reportData = res?.data?.data;
            if (report === "xlsx") {
              exportTOExcelFile(reportData);
            } else {
              window.open(`${res?.data?.data}`, "_blank");
            }
            // window.open(`${DOC_DOWNLOAD_URL}${res?.data?.data}`, "_blank");
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.timesheets?.total,
              totalPages: Math.ceil(
                res?.data?.data?.timesheets?.total /
                  res?.data?.data?.timesheets?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        toast.error(`${error}`);
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    fetchListReports(false, e);
    // if (e === "xlsx") {
    //   exportTOExcelFile();
    // } else {
    //   fetchParticipantListReports(false, e);
    // }
  };

  const exportTOExcelFile = (reportData) => {
    const transformedData =
      reportData &&
      reportData?.timesheets?.length > 0 &&
      reportData?.timesheets?.map((item) => {
        const workersString =
          item.participants && item?.participants?.length > 0
            ? item.participants
                .map((el) => `${el.first_name} ${el.last_name}`)
                .join(", ")
            : "";

        return {
          Participants: workersString || "N/A",
          Worker: `${item?.worker?.first_name} ${item?.worker?.last_name}` || "N/A",

          TimeSheetDate: item?.time_sheet_date ? moment(item?.time_sheet_date).format("DD/MM/YYYY") : "N/A",
          StartTime: item?.start_time ? moment(item?.start_time).format("DD/MM/YYYY hh:mm") : "N/A",
          EndTime: item?.end_time ? moment(item?.end_time).format("DD/MM/YYYY hh:mm") : "N/A",
          TimesheetLength: item?.timesheet_length || "N/A",
          Incident: item?.incident || "N/A",
          Status: item?.status || "N/A",
          KMs:item?.km || "N/A",
          Comment: item?.comment || "N/A",
          Note: item?.note || "N/A",
        };
      });
    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header title='Timesheet Reports' />
          <div className='min-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.TIMESHEET_REPORTS
                )}
              />
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by participant name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='flex py-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant
                        </label>
                        <SelectOption
                          name='pt_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedParticipant}
                          handleChange={(e) => {
                            setSelectedParticipant(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Worker
                        </label>
                        <SelectOption
                          name='worker_id'
                          isSearch={true}
                          data={workerList || []}
                          selectedOption={selectedWorker}
                          handleChange={(e) => {
                            setSelectedWorker(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Date Range of Timesheet (Max 3 Months){" "}
                          <span className='text-red-400'>*</span>
                        </label>
                        <DateRangePickerNew
                          // isDisabled={clientsListLoader}
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                            setReports([]);
                          }}
                          handleChange={(obj) => {
                        
                            if (obj && obj.fromDate && obj.toDate) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          maxDate={addMonths(selectedFromDate, 3)}
                          setIsDisableSearch={setIsDisableSearch}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end pt-4 space-x-2'
                        style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={() => fetchListReports(false)}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || isDisableSearch}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setSelectedWorker(null);
                            setSelectedFromDate(null)
                            setSearch(null);
                            setReports([]);
                            setFromDate(null)
                            setToDate(null)
                            setSelectedParticipant(null);
                            // fetchListReports(true);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className='min-w-full  flex justify-center overflow-x-auto custom-scroll'>
                <table className='border min-w-full divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader
                         isSort={false}
                        label={"Participant"}
                      />

                      <TableHeader
                          isSort={false}
                        label={"Worker"}
                      />

                      <TableHeader
                        sortName='time_sheet_date'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Timesheet Date"}
                      />

                      <TableHeader
                        sortName='worker_start_time'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Worker Start Time"}
                      />

                      <TableHeader
                        sortName='worker_end_time'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Worker End Time"}
                      />

                      <TableHeader
                        sortName='timesheet_length'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Total Time"}
                      />

                      <TableHeader
                         isSort={false}
                        label={"Shift Notes"}
                      />

                      <TableHeader
                       isSort={false}
                        label={"Incidents"}
                      />

                      <TableHeader
                        sortName='km'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"KMs"}
                      />

                      <TableHeader
                          isSort={false}
                        label={"Start Shift Comment"}
                      />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={10} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen  items-center justify-start`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.timesheets?.data?.length > 0 ? (
                      reportList?.timesheets?.data?.map((timesheet, index) => (
                        <tr
                          key={index}
                          className='even:bg-white odd:bg-gray-50'
                        >
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div>
                              {(timesheet?.participants &&
                                _.isArray(timesheet?.participants) && (
                                  <AvatarGroup
                                    type='participant'
                                    arrayData={timesheet?.participants || []}
                                    cssClass='mt-0'
                                    maxLength={5}
                                  />
                                )) ||
                                "-"}
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {`${timesheet?.worker?.first_name} ${timesheet?.worker?.last_name}`}
                          </td>
                          <td className='px-3 text-left py-[11px] text-sm text-gray-500'>
                            {timesheet?.time_sheet_date
                              ? moment(timesheet.time_sheet_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                          {moment(timesheet?.start_time).format("DD/MM/YYYY HH:mm:ss")}
                            {/* {timesheet?.start_time || "-"} */}
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                          {moment(timesheet?.end_time).format("DD/MM/YYYY HH:mm:ss")}
                            {/* {timesheet?.end_time || "-"} */}
                          </td>

                          <td className='px-3 text-left text-sm text-gray-500'>
                            {timesheet.timesheet_length || "-"}
                          </td>

                          <td className='truncate py-4  px-3 text-sm'>
                            <div
                              className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                              style={{
                                maxWidth: "100px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {timesheet?.note ? (
                                <PopoverContainer
                                  cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                                  itemComponent={() => {}}
                                  toolTip={`${timesheet?.note}`}
                                >
                                 
                                  <span>{truncateTextReporting(timesheet?.note)}</span>

                                </PopoverContainer>
                              ) : (
                                "-"
                              )}
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                          <div
                              className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                              style={{
                                maxWidth: "100px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {timesheet?.incident ? (
                                <PopoverContainer
                                  cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                                  itemComponent={() => {}}
                                  toolTip={`${timesheet?.incident}`}
                                >
                             
                                  <span>{truncateTextReporting(timesheet?.incident)}</span>
                                </PopoverContainer>
                              ) : (
                                "-"
                              )}
                            </div>
                          
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                            {timesheet?.km || "-"}
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                            <div
                              className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                              style={{
                                maxWidth: "100px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {timesheet?.comment ? (
                                <PopoverContainer
                                  cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                                  itemComponent={() => {}}
                                  toolTip={`${timesheet?.comment}`}
                                >
                               
                                  <span>{truncateTextReporting(timesheet?.comment)}</span>

                                  
                                </PopoverContainer>
                              ) : (
                                "-"
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={10}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please adjust your filters to get
                          relevant results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {reportList && reportList?.timesheets?.data?.length > 0 && (
                <div className='mt-3'>
                  <PaginationComponent
                    page={paginationObj?.page || 1}
                    activeListLength={reportList?.timesheets?.data || []}
                    limit={REPORTING_LIMIT}
                    totalList={paginationObj?.total}
                    handlePagination={(e) => {
                      setPage(e);
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });
                    }}
                    totalPages={paginationObj?.totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TimesheetReports;
