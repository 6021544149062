import React from "react";
import moment from "moment";
import { BsPlusLg } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import TableHeader from "components/common/Table/TableHeader";
import PopoverContainer from "components/common/Popover/PopoverContainer";
import { truncateTextReporting } from "Utils/utils";

const DetailedInvoicingSubTable = (props) => {
  const { item } = props;
  const [expandedRows, setExpandedRows] = React.useState([]);

  const handleRowClick = (index) => {
    const newExpandedRows = [...expandedRows];
    if (expandedRows.includes(index)) {
      const indexToRemove = newExpandedRows.indexOf(index);
      newExpandedRows.splice(indexToRemove, 1);
    } else {
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };

  return (
    <>
      <tr className="text-center even:bg-white odd:bg-gray-50">
        <td
          className="flex justify-center items-center h-[50px] cursor-pointer"
          onClick={() => handleRowClick(item.uuid)}
        >
          <div className="flex align-center cursor-pointer">
            {expandedRows.includes(item.uuid) ? (
              <BiMinus className="mt-1 text-gray-600" />
            ) : (
              <BsPlusLg className="h-3 w-3 mt-1 text-gray-600" />
            )}
          </div>
        </td>

        <td className="px-3 py-4 text-sm text-gray-500 text-left capitalize">
          <div className="text-gray-500 capitalize">
            {item?.invoice_number || "N/A"}
          </div>
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
          {item?.created_by || "N/A"}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
          {item?.created_at
            ? moment(item?.created_at,"DD-MM-YYYY").format("DD/MM/YYYY")
            : "N/A"}
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
          {item?.total_km || "N/A"}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
          {item?.total_hours || "N/A"}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
          {`$${item?.total_amount}` || "N/A"}
        </td>
      </tr>

      {expandedRows.includes(item.uuid) && (
        <tr className="text-center">
          <td colSpan={7} className="px-3 py-4">
            <table className="min-w-full divide-y divide-gray-300 border">
              <thead>
                <tr>
                  <TableHeader
                    isSort={false}
                    label={"Participant"}
                    cssClass="text-left text-xs"
                  />
                  <TableHeader
                    isSort={false}
                    label={"Worker"}
                    cssClass="text-left text-xs"
                  />
                  <TableHeader
                    isSort={false}
                    label={"Worker Start Time"}
                    cssClass="text-left text-xs"
                  />
                  <TableHeader
                    isSort={false}
                    label={"Worker End Time"}
                    cssClass="text-left text-xs"
                  />
                  <TableHeader
                    isSort={false}
                    label={"Support Item Name"}
                    cssClass="text-left text-xs"
                  />
                  <TableHeader
                    isSort={false}
                    label={"Support Item Code"}
                    cssClass="text-left text-xs"
                  />
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-whit">
                {item?.invoice_detail && item?.invoice_detail?.length > 0 ? (
                  item?.invoice_detail?.map((el, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                        <div
                          className="font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize"
                          style={{
                            maxWidth: "100px",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {el?.participants
                            ? (() => {
                                try {
                                  const participants = JSON.parse(
                                    el.participants
                                  );
                                  const dynamicKey =
                                    Object.keys(participants)[0];
                                  const participantName =
                                    participants[dynamicKey];

                                  return (
                                    <PopoverContainer
                                      cssClass="text-white text-sm bg-gradient p-2 rounded-md z-10"
                                      itemComponent={() => {}}
                                      toolTip={participantName}
                                    >
                                      <span>
                                        {truncateTextReporting(participantName)}
                                      </span>
                                    </PopoverContainer>
                                  );
                                } catch (error) {
                                  console.error(
                                    "Error parsing participants:",
                                    error
                                  );
                                  return "-";
                                }
                              })()
                            : "-"}
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                        <div
                          className="font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize"
                          style={{
                            maxWidth: "100px",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {el?.workers
                            ? (() => {
                                try {
                                  const workers = JSON.parse(el.workers);
                                  const dynamicKey = Object.keys(workers)[0];
                                  const workerName = workers[dynamicKey];

                                  return (
                                    <PopoverContainer
                                      cssClass="text-white text-sm bg-gradient p-2 rounded-md z-10"
                                      itemComponent={() => {}}
                                      toolTip={workerName}
                                    >
                                      <span>
                                        {truncateTextReporting(workerName)}
                                      </span>
                                    </PopoverContainer>
                                  );
                                } catch (error) {
                                  console.error(
                                    "Error parsing workers:",
                                    error
                                  );
                                  return "-";
                                }
                              })()
                            : "-"}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                        <div className="text-gray-500">
                          {el?.worker_start_time
                            ? moment(el?.worker_start_time).format(
                                "DD/MM/YYYY HH:mm"
                              )
                            : "N/A"}
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                        {el?.worker_end_time
                          ? moment(el?.worker_end_time).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                        <div
                          className="font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize"
                          style={{
                            maxWidth: "100px",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {el?.support_name ? (
                            <PopoverContainer
                              cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                              itemComponent={() => {}}
                              toolTip={`${el?.support_name}`}
                            >
                              <span>
                                {truncateTextReporting(el?.support_name)}
                              </span>
                            </PopoverContainer>
                          ) : (
                            "-"
                          )}
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                        <div
                          className="font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize"
                          style={{
                            maxWidth: "140px",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {el?.item_number ? (
                            <PopoverContainer
                              cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                              itemComponent={() => {}}
                              toolTip={`${el?.item_number}`}
                            >
                              <span>
                                {truncateTextReporting(el?.item_number)}
                              </span>
                            </PopoverContainer>
                          ) : (
                            "-"
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="py-6 text-sm text-center text-red-600"
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default DetailedInvoicingSubTable;
