import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";

import Search from "components/common/Search/Search";

import PaginationComponent from "components/common/Pagination/index";
import {
  emptyPaginationObj,
  REPORTING_LIMIT,
  SORT_TYPE,
} from "Constants/constant";

import { apiPostRequest } from "helpers/Requests";
import { URL_REPORTING_COS_REPORTS } from "store/services/URL";

import { FiDelete } from "react-icons/fi";
import _ from "lodash";
import { toast } from "react-toastify";

import { addMonths, exportTOExcel, getPlanManagedBy } from "Utils/utils";

import CaseNotesTable from "./CaseNotesTable";
import moment from "moment";

const COSReports = ({
  clientsList,
  getClientList,

  supportCoordinator,
  getSupportCoordinatoreList,
  tableDataForReportingLoading,
}) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  useEffect(() => {
    getClientList();
    getSupportCoordinatoreList();
  }, []);

  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [workerList, setWorkerList] = useState([]);
  const [participantList, setParticipant] = useState([]);

  useEffect(() => {
    const updateLists = async () => {
      const workers =
        supportCoordinator?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedWorkers = workers.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setWorkerList(sortedWorkers);

      const participants =
        clientsList?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedParticipants = participants.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setParticipant(sortedParticipants);

      setParticipant((prevParticipantList) => [
        { label: "All", value: "all" },
        ...prevParticipantList,
      ]);

      setWorkerList((prevWorkerList) => [
        { label: "All", value: "all" },
        ...prevWorkerList,
      ]);
    };

    updateLists();
  }, [clientsList, supportCoordinator]);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [selectedClient, setSelectedClient] = useState(null);
  const [serviceContract, setserviceContract] = useState(null);

  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [isDisableSearch, setIsDisableSearch] = useState(false);

  useEffect(() => {
    if (!isInitialRender) {
      fetchListReports(false, null, {
        page,
        sort_field: sortBy,
        sort_order: sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
      });
    }
  }, [page]);

  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = {}
  ) => {
    if (!fromDate && !toDate) {
      toast("Select  date range to get data");
      return;
    }
    const currentPage = filters?.pgae || page;
    setLoader(true);
    let url = `${URL_REPORTING_COS_REPORTS}?per_page=25&page=${currentPage}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_COS_REPORTS}?per_page=25&page=${currentPage}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",
      pt_id: isClearAll
        ? "all"
        : selectedClient
        ? selectedClient?.value
        : "all",
      sp_cos_id: isClearAll
        ? "all"
        : serviceContract
        ? serviceContract?.value
        : "all",
      date_range: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
    };
    if (report) {
      payload.report = report === "xlsx" ? "excel" : report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            if (report === "xlsx") {
              const data = res?.data?.data;
              exportTOExcelFile(data);
            } else {
              window.open(`${res?.data?.data}`, "_blank");
            }
            // window.open(`${DOC_DOWNLOAD_URL}${res?.data?.data}`, "_blank");
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => {
              return {
                ...prev,
                total: res?.data?.data?.casenotes?.total,
                totalPages: Math.ceil(
                  res?.data?.data?.casenotes?.total /
                    res?.data?.data?.casenotes?.per_page
                ),
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    fetchListReports(false, e);
  };

  const exportTOExcelFile = (reportData) => {
    let transformedData = null;

    transformedData =
      reportData &&
      reportData.casenotes?.length > 0 &&
      reportData?.casenotes?.map((item) => {
        return {
          Participant: `${item?.participant?.first_name} ${item?.participant?.last_name}`,
          Delivery_Serial_No: item?.delivered_service_number || "N/A",
          Support_Item_Name: item?.support_item_name || "N/A",
          Created_By: item?.created_by || "N/A",
          Created_Date: item?.created_at
            ? moment(item?.created_at).format("DD/MM/YYYY")
            : "N/A",
          Managment_Style: item?.manged_by
            ? getPlanManagedBy(item?.manged_by)
            : "N/A",
          Delivered_Date: item?.delivered_date
            ? moment(item?.delivered_date).format("DD/MM/YYYY")
            : "N/A",
          Hours: item?.hours || "N/A",
          Rate: item?.original_rate || "N/A",
          Total_Delivered_Amount: item?.total_amount || "N/A",
          Status: item?.status || "N/A",
          Notes: item?.notes && item?.notes !== "null" ? item?.notes : "N/A",
        };
      });

    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };
  const handleSearch = () => {
    setPage(1);

    const updatedPaginationObj = { ...paginationObj, page: 1 };
    setPaginationObj(updatedPaginationObj);

    fetchListReports(false, null, {
      page: 1,
      sort_field: sortBy,
      sort_order: sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };
  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header title='COS Case Note Reports' />
          <div className='min-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.COS_REPORTS
                )}
              />
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by participant name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='flex py-2 w-full justify-between items-end space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant
                        </label>
                        <SelectOption
                          isDisabled={tableDataForReportingLoading}
                          name='pt_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedClient}
                          handleChange={(e) => {
                            setSelectedClient(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Support Coordinators
                        </label>
                        <SelectOption
                          isDisabled={tableDataForReportingLoading}
                          name='contact_type_id'
                          isSearch={true}
                          data={workerList || []}
                          handleChange={(e) => {
                            setserviceContract(e);
                          }}
                          selectedOption={serviceContract}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-sm font-medium text-gray-700 text-start'>
                          Create Date Range (Max 3 months){" "}
                          <span className='text-red-400'>*</span>
                        </label>
                        <DateRangePickerNew
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                            setReports([]);
                          }}
                          handleChange={(obj) => {
                            if (obj) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          maxDate={addMonths(selectedFromDate, 3)}
                          setIsDisableSearch={setIsDisableSearch}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end  space-x-2'
                        style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={handleSearch}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || isDisableSearch}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setSearch(null);
                            setReports([]);
                            setFromDate(null);
                            setToDate(null);
                            setSelectedClient(null);
                            setserviceContract(null);
                            setSelectedFromDate(null);
                            // fetchListReports(true);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div>
                <CaseNotesTable
                  flipSort={flipSort}
                  sortDescending={sortDescending}
                  sortBy={sortBy}
                  loader={loader}
                  reportList={reportList}
                />
              </div>

              {reportList && reportList?.casenotes?.data?.length > 0 && (
                <div className='mt-3'>
                  <PaginationComponent
                    page={paginationObj?.page || 1}
                    activeListLength={reportList?.casenotes?.data || []}
                    limit={REPORTING_LIMIT}
                    totalList={paginationObj?.total}
                    handlePagination={(e) => {
                      setPage(e);
                      setPaginationObj((prev) => ({ ...prev, page: e }));
                      fetchListReports(false, null, {
                        page: e,
                        sort_field: sortBy,
                        sort_order: sortDescending
                          ? SORT_TYPE.DESC
                          : SORT_TYPE.ASC,
                      });
                    }}
                    totalPages={paginationObj?.totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default COSReports;
