import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";
import Search from "components/common/Search/Search";

import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  emptyPaginationObj,
  REPORTING_LIMIT,
  SORT_TYPE,
  StatusOptions,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import {
  URL_REPORTING_COS_FUNDING_REPORTS,
  URL_REPORTING_PARTICIPANT_REPORTS,
} from "store/services/URL";
import moment from "moment";
import { FiDelete } from "react-icons/fi";
import _ from "lodash";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { toast } from "react-toastify";
import { addMonths, exportTOExcel } from "Utils/utils";
import FundingTable from "../COSReports/FundingTable";
import FundingSubTable from "./FundingSubTable";
import { PLAN_CATEGORY_COS_FUNDING_REPORT } from "store/constants";

const FundingReports = ({ getParticipantList, spParticipants }) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
  }, []);

  const [participantList, setParticipant] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [status, setStatus] = useState(null);

  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [serviceProviderList, setServiceProvider] = useState([]);
  const [selectedSP, setSelectedSP] = useState(null);

  useEffect(() => {
    if (spParticipants?.participant?.data) {
      const participants = spParticipants.participant.data
        .map(({ first_name, last_name, uuid }) => ({
          label: `${first_name} ${last_name}`,
          value: uuid,
        }))
        .sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        );

      setParticipant([{ label: "All", value: "all" }, ...participants]);
    }
  }, [spParticipants]);

  useEffect(() => {
    const res = apiGetRequest("cos/external-sp?page=1&per_page=1000");
    res
      .then((res) => {
        if (res.status === 200) {
          const responseData = res.data?.external_sp?.data;
          const modifiedData = responseData
            ?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))
            .sort((a, b) =>
              a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            );

          setServiceProvider(modifiedData);
        }
      })
      .catch((error) => console.log({ error }));
  }, []);
  const [isDisableSearch, setIsDisableSearch] = useState(false);

  useEffect(() => {
    if (!isInitialRender) {
      fetchParticipantListReports(false, null, {
        page,
        sort_field: sortBy,
        sort_order: sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
      });
    }
  }, [page]);


  const fetchParticipantListReports = async (
    isClearAll = false,
    report = null,
    filters = {}
  ) => {
    if (!fromDate && !toDate) {
      toast("Select create date range to get data");
      return;
    }
    const currentPage = filters.page || page;
    setLoader(true);
    let url = `${URL_REPORTING_COS_FUNDING_REPORTS}?per_page=25&page=${currentPage}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_COS_FUNDING_REPORTS}?per_page=25&page=${currentPage}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",
      pt_id: isClearAll
        ? "all"
        : selectedParticipant
        ? selectedParticipant?.value
        : "all",
      external_sp_id: isClearAll
        ? "all"
        : selectedSP
        ? selectedSP?.value
        : "all",
      sp_cos_id: "all",
      service_category_id: isClearAll ? "all" : status ? status.value : "all",

      date_range: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
      // date_range: "all",
    };
    if (report) {
      payload.report = report === "xlsx" ? "excel" : report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);

          if (payload.hasOwnProperty("report")) {
            if (report === "xlsx") {
              const data = res?.data?.data;

              exportTOExcelFile(data);
            } else {
              window.open(`${res?.data?.data}`, "_blank");
            }
         
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.cos_fundings?.total,
              totalPages: Math.ceil(
                res?.data?.data?.cos_fundings?.total /
                  res?.data?.data?.cos_fundings?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    fetchParticipantListReports(false, e);
  };

  const exportTOExcelFile = (reportData) => {
    const transformedData =
      reportData &&
      reportData.cos_fundings &&
      reportData?.cos_fundings?.flatMap((item) => {
       
        if (item.categories && item?.categories?.length > 0) {
          return item?.categories?.map((el) => {
            return {
              Funding_Id: item?.service_contract_ref_no || "N/A",
              Participant:
                `${item?.participant?.first_name} ${item?.participant?.last_name}` ||
                "N/A",
              Start_Data: item?.start_date
                ? moment(item?.start_date).format("DD/MM/YYYY")
                : "N/A",
              End_Date: item?.end_date
                ? moment(item?.end_date).format("DD/MM/YYYY")
                : "N/A",
              Total_Funding: item?.total_service_contract_amount || "N/A",
              Total_Delivered: item?.amount_delivered || "N/A",
              Remaining_Amount: item?.amount_remaining || "N/A",
              Plan_Ctegory: el?.service_category?.name || "N/A",
              Supoort_Item_Name: el?.service_sub_category?.title || "N/A",
              External_Service_Provider:
                (el?.service_sub_category &&
                  el?.service_sub_category?.external_sp?.name) ||
                "N/A",
              Total_Budget_Amount: el?.total_budget_amount || "N/A",
              Total_Budget_Delivered: el?.amount_delivered || "N/A",
              Remaining_Budget_Amount: el?.amount_remaining || "N/A",
            };
          });
        } else {
          return [];
        }
      });
    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchParticipantListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };
  const handleSearch = () => {
    setPage(1);

    const updatedPaginationObj = { ...paginationObj, page: 1 };
    setPaginationObj(updatedPaginationObj);

    fetchParticipantListReports(false, null, {
      page: 1,
      sort_field: sortBy,
      sort_order: sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <div className='min-h-full bg-red-500'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header title='COS Funding Reports' />
          <div className='min-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.FUNDING_REPORTS
                )}
              />
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by participant name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='flex pt-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant Name
                        </label>
                        <SelectOption
                          name='pt_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedParticipant}
                          handleChange={(e) => {
                            setSelectedParticipant(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        {/* <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Funding Start Date Range
                        </label> */}
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                        Funding Start Date Range (Max 3 Months){" "}
                          <span className='text-red-400'>*</span>
                        </label>
                        <DateRangePickerNew
                          // isDisabled={clientsListLoader}
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                            setReports([]);
                          }}
                          handleChange={(obj) => {
                            if (obj && obj.fromDate && obj.toDate) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          maxDate={addMonths(selectedFromDate, 3)}
                          setIsDisableSearch={setIsDisableSearch}
                        />
                      </div>
                    </div>

                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Plan Category
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={PLAN_CATEGORY_COS_FUNDING_REPORT || []}
                          handleChange={(e) => {
                            setStatus(e);
                          }}
                          selectedOption={status}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label
                          htmlFor='from-date-to-to-date'
                          className='block text-sm font-medium text-gray-700'
                        >
                          External Service Provider
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={serviceProviderList || []}
                          handleChange={(e) => {
                            setSelectedSP(e);
                          }}
                          selectedOption={selectedSP}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='flex py-2 w-full justify-end items-end space-x-2'>
                    <div className='w-full'>
                      <div className='flex d-flex justify-end  space-x-2'>
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={handleSearch}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || isDisableSearch}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setToDate(null);
                            setFromDate(null);

                            setStatus(null);
                            setSelectedSP(null);
                            setSearch(null);
                            setReports([]);

                            setSelectedParticipant(null);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div className='overflow-x-auto custom-scroll'>
                <table className='border divide-y min-w-full divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader isSort={false} label={""} />
                      <TableHeader isSort={false} label={"Funding Id"} />

                      <TableHeader isSort={false} label={"Participant Name"} />

                      <TableHeader
                        sortName='start_date'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Funding Start Date"}
                      />

                      <TableHeader
                        sortName='end_date'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Funding End Date"}
                      />

                      <TableHeader
                        sortName='total_contract_amount'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Total Funding "}
                      />

                      <TableHeader
                        sortName='amount_remaining'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Remaining Amount"}
                      />

                      <TableHeader
                        sortName='amount_delivered'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Total Delivered"}
                      />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={15} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen  items-center justify-start`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.cos_fundings?.data?.length > 0 ? (
                      reportList?.cos_fundings?.data?.map((item, index) => (
                        <FundingSubTable item={item} key={item.uuid} />
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={15}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please adjust your filters to get
                          relevant results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {reportList && reportList?.cos_fundings?.data?.length > 0 && (
                <div className='mt-3'>
                  <PaginationComponent
                    page={paginationObj?.page || 1}
                    activeListLength={reportList?.cos_fundings?.data || []}
                    limit={REPORTING_LIMIT}
                    totalList={paginationObj?.total}
                    handlePagination={(e) => {
                      setPage(e);
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });
                      fetchParticipantListReports(false, null, {
                        page: e,
                        sort_field: sortBy,
                        sort_order: sortDescending
                          ? SORT_TYPE.DESC
                          : SORT_TYPE.ASC,
                      });
                    }}
                    totalPages={paginationObj?.totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default FundingReports;
